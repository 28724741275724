// Here you can add other styles
/* Largura da barra de rolagem */
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

/* Cor do fundo da barra de rolagem */
::-webkit-scrollbar-track {
    background: var(--cui-card-bg);
}

/* Cor da thumb da barra de rolagem */
::-webkit-scrollbar-thumb {
    background: var(--cui-light);
    border-radius: 4px;
}

/* Quando a thumb da barra de rolagem é hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.text-gray-700 {
    color: var(--cui-gray-700);
}
